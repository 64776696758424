<script setup>
import { ref } from "vue";
import IconChevronDown from "./icons/IconChevronDown.vue";

const props = defineProps({
  title: String,
});

const accordionContent = ref(null);
const opened = ref(false);

function toggleAccordion() {
  opened.value = !opened.value;

  if (opened.value) {
    accordionContent.value.style.height =
      accordionContent.value.scrollHeight + "px";
    accordionContent.value.style.marginTop = "10px";
    accordionContent.value.style.marginBottom = "10px";
  } else {
    accordionContent.value.style.height = "0px";
    accordionContent.value.style.marginTop = "0px";
    accordionContent.value.style.marginBottom = "0px";
  }
}
</script>

<template>
  <div class="accordion">
    <div class="accordion-header" @click="toggleAccordion">
      <span class="title"> {{ props.title }} </span>
      <IconChevronDown class="icon" :class="{ iconAnimated: opened }" />
    </div>

    <div class="accordion-content" ref="accordionContent">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.accordion {
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  overflow: hidden;
  min-width: 150px;
  max-width: 200px;
  width: 100%;
  border: 1px solid #dee2e6;
  background-color: #fff;
}

.accordion .accordion-header {
  display: flex;
  min-height: 50px;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #f8f9fa; */
  background-color: #fff;
}

.accordion .accordion-header .title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.accordion .accordion-content {
  padding: 0 15px;
  height: 0;
  transition: all 0.5s ease;
  color: #000;
}

.icon {
  transform: rotateZ(0deg);

  transition: all 0.5s ease;
}

.iconAnimated {
  transform: rotateZ(180deg);
}
</style>
