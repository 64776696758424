<script setup>
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";

const filterStore = useFilterStore();
const {
  availableFilters,
  breadCrumb_path,
  breadCrumb_index,
  currentStepHome,
  searchedProduct,
  categoriesToDisplay,
  queryStringSearch,
} = storeToRefs(filterStore);
const { getSubcategories, getInitialProductsSet } = filterStore;

function crumbsMove(section, index) {
  queryStringSearch.value = "";

  const sectionName = section.name;
  const sectionId = section.id;

  if (section.type) {
  } else {
    currentStepHome.value = "ProductsList";
    searchedProduct.value = null;

    if (section.name == "Home") {
      for (let i = breadCrumb_path.value.length - 1; i > index; i--) {
        breadCrumb_path.value.pop();
        breadCrumb_index.value--;
      }
      queryStringSearch.value = "";
      categoriesToDisplay.value = [];

      getInitialProductsSet();
      availableFilters.value = [];
    } else if (section.name == "Resultado da pesquisa") {
      return;
    } else if (
      sectionName == breadCrumb_path.value[breadCrumb_index.value].name
    ) {
      return;
    } else {
      for (let i = breadCrumb_path.value.length - 1; i >= index; i--) {
        breadCrumb_path.value.pop();
        breadCrumb_index.value--;
      }
      getSubcategories(sectionName, sectionId);
    }
  }
}

function goback() {
  categoriesToDisplay.value = [];
  breadCrumb_path.value.pop();
  breadCrumb_index.value--;
  queryStringSearch.value = "";

  const section = breadCrumb_path.value[breadCrumb_index.value];

  if (section.name == "Home") {
    getInitialProductsSet();
    currentStepHome.value = "ProductsList";
    availableFilters.value = [];
  } else {
    breadCrumb_path.value.pop();
    breadCrumb_index.value--;
    currentStepHome.value = "ProductsList";

    getSubcategories(section.name, section.id);
  }
}
</script>

<template>
  <div v-if="breadCrumb_path.length > 1">
    <p>
      <span class="arrow" @click="goback">{{ "<-" }}</span>
      <span
        v-for="(section, index) in breadCrumb_path"
        :key="section.id"
        :class="{ active: index === breadCrumb_index }"
        @click="crumbsMove(section, index)"
        class="span_container"
      >
        <span>{{ section.name }}</span>
        <span v-if="!(index == breadCrumb_path.length - 1)">{{ " -> " }}</span>
      </span>
    </p>
  </div>
</template>

<style scoped>
div {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-right: 30px;
  cursor: pointer;
}

.active {
  font-weight: 500;
  color: #ff4f00;
}

.span_container {
  cursor: pointer;
}
</style>
