<script setup>
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useFilterStore } from "../../stores/filterStore";

const filterStore = useFilterStore();
const { searchedProduct, breadCrumb_index, breadCrumb_path, availableFilters } =
  storeToRefs(filterStore);

const { actionWatcher } = filterStore;

function formatCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

onMounted(() => {
  // Zerando os filtros para não aparecerem na tela de produto
  availableFilters.value = [];

  console.log("Objeto antes do fetch: ", searchedProduct.value);

  let url = process.env.VUE_APP_URL + "/getPriceSimp";
  let nB = { stock_id: searchedProduct.value.id };
  fetch(url, {
    method: "POST",
    body: JSON.stringify(nB),
    headers: new Headers({
      "content-type": "application/json",
    }),
  })
    .then((res) => res.json())
    .then((content) => {
      console.log(content);
      //Du, o objeto está sendo montado como um vetor, mas estamos com algum erro de casting na montagem do Objeto.
      //Eu tentei mexer no for each, mas como o objeto está sendo montado em vários pontos preferi deixar pra ti
      searchedProduct.value.available_stores = content.pce;

      console.log("Objeto pós o fetch: ", searchedProduct.value);
    })
    .catch((err) => {
      console.error("Erro na /getPriceSimp: " + err);
    });

  breadCrumb_path.value.forEach((element, index) => {
    console.log("Elemento: ", element);
    if (element.id == -1) {
      breadCrumb_path.value.splice(index, 1);
      breadCrumb_index.value--;
    }
  });
});

function formatFields(fields) {
  // check if fields is an array
  if (Array.isArray(fields)) {
    let string = "";
    fields.forEach((field) => {
      string += `${field}, `;
    });
    return string;
  } else {
    return fields;
  }
}
</script>

<template>
  <div class="container">
    <div class="hero">
      <div class="wrapper_img">
        <img
          :src="`https://storage.googleapis.com/img_products/${searchedProduct.id}_1_standard_1.jpg`"
          alt=""
        />
      </div>
      <div class="infos">
        <h2>{{ searchedProduct.name }}</h2>
        <p>Fabricante: {{ searchedProduct.manufacturer }}</p>
        <p>Em estoque: {{ searchedProduct.in_stock }}</p>
        <br />
        <div class="buttons_wrapper">
          <a
            v-for="store in searchedProduct.available_stores"
            :key="store.store_id"
            :href="store.product_url"
            target="_blank"
            class="btn_price"
            @click="
              actionWatcher(
                'RedirectStore',
                store.store_name,
                store.store_id,
                store.id_product_store
              )
            "
            >{{ `${formatCurrency(store.price)} em ${store.store_name}` }}</a
          >
        </div>
        <div class="fields_table" v-if="searchedProduct.fields">
          <table>
            <tr v-for="field in Object.keys(searchedProduct.fields)">
              <td class="table_description">{{ field }}</td>
              <td>{{ formatFields(searchedProduct.fields[field]) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.hero {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  position: relative;
}

.hero .wrapper_img {
  width: 300px;
  height: 300px;
  background-color: #c6c6c6;
  margin-bottom: 20px;
  position: sticky;
}

.hero .wrapper_img img {
  width: 100%;
  height: 100%;
}

.hero .infos {
  /* background-color: pink; */
}

.hero .infos h2 {
  font-size: 22px;
  max-width: 400px;
  margin-bottom: 10px;
}

.hero .infos p {
  font-size: 15px;
  margin: 8px 0;
}

.hero .infos .buttons_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.hero .infos .btn_price {
  background-color: #ff4f00;
  display: block;
  width: max-content;

  padding: 16px 80px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.hero .infos .fields_table {
  /* background-color: red; */
}

.hero .infos .fields_table table {
  /* background-color: burlywood; */
}

.hero .infos .fields_table table tr {
  /* background-color: blue; */
  border-bottom: 1px solid #c6c6c6;
  border-top: 1px solid #c6c6c6;
}

.hero .infos .fields_table table tr td {
  padding: 12px;
}

.hero .infos .fields_table table tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* Definindo o background para as linhas ímpares */
.hero .infos .fields_table table tr:nth-child(odd) {
  background-color: #f0f2f2; /* ou qualquer outra cor desejada */
}
</style>
