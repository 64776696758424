import { defineStore } from "pinia";
import { ref } from "vue";

export const useFilterStore = defineStore("filterStore", () => {
  const filteredValues = ref([]);
  const availableFilters = ref([]);
  const breadCrumb_index = ref(0);
  const sidebarCategories = ref([]);
  const searchFailed = ref(false);

  const categoriesToDisplay = ref([]); // Categories to display in productsList
  const breadCrumb_path = ref([
    {
      name: "Home",
      id: 0,
    },
  ]);
  const currentStepHome = ref("ProductsList");
  const searchedProduct = ref(null);

  const queryStringSearch = ref("");
  const searchResults = ref([]);

  function actionWatcher(currentSection, store, storeID, prodStoreID) {
    console.log("Rodando watcher");
    let nB = {};
    let actionTarget = "Acesso Home Onstoq";

    let lastCatName = breadCrumb_path.value[breadCrumb_index.value]["name"];
    console.log("lastCatFromBreadCrumb: ", lastCatName);
    let lastCatId = breadCrumb_path.value[breadCrumb_index.value]["id"];
    console.log("lastCatFromBreadCrumb: ", lastCatId);

    //Por enqt a lastCatFromBreadCrumb faz tudo porque a ultima cat que o usuario acessa é o produto.
    // let product = searchedProduct.value
    // console.log('product: ', product)

    console.log("currentSection: ", currentSection);

    switch (currentSection) {
      case "Home":
        nB = { currentSection, actionTarget };
        console.log("nB HOME: ", nB);
        console.log(nB);
        break;
      case "Category":
        let catID = lastCatId;
        actionTarget = "Acessou a categoria " + lastCatName;
        console.log("ProductList: ", actionTarget);
        nB = { currentSection, actionTarget, catID };
        console.log("nB ProductList: ", nB);
        console.log(nB);
        break;
      case "Product":
        let prodID = lastCatId;
        actionTarget = "Acessou o produto " + lastCatName;
        nB = { currentSection, actionTarget, prodID };
        console.log("Product: ", actionTarget);
        console.log("nB Product: ", nB);
        console.log(nB);
        break;
      case "RedirectStore":
        if (store) {
          actionTarget = `Entrou no link do produto ${lastCatName} na loja ${store}`;
          nB = { currentSection, actionTarget, storeID, prodStoreID };
          console.log("Product: ", actionTarget);
          console.log("nB Product: ", nB);
          console.log(nB);
        }
        break;
    }
    let url = process.env.VUE_APP_URL + "/storeAct";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(nB),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((content) => {
        console.log("actionWatcher: ", content);
      })
      .catch((err) => {
        console.error("Erro na /getSubcategories(front): " + err);
      });
  }

  function getInitialProductsSet() {
    searchFailed.value = false;

    let url = process.env.VUE_APP_URL + "/InitialProducts";
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((content) => {
        categoriesToDisplay.value = content.content;

        console.log("CATEGORIES TO DISPLAY", categoriesToDisplay.value);
      })
      .catch((err) => {
        console.error("Erro na /getInitialProductsSet: ", err);
      });
  }

  function filterProducts() {
    let url5 = process.env.VUE_APP_URL + "/getFilteredResults";

    let lastCatFromBreadCrumb =
      breadCrumb_path.value[breadCrumb_index.value]["id"];
    //Aqui a telemetria buga, não avisa direito que foram usados filtros pra pesquisa, nem quais foram usados, nem os valores.
    //Simplesmente registra que entrou na categoria novamente
    actionWatcher("Category");

    const data = {
      filtersUsed: filteredValues.value,
      catID: lastCatFromBreadCrumb,
    };

    // Se tiver algum filtro com values vazio, remove ele do array
    filteredValues.value.forEach((filter) => {
      if (
        filter.values === undefined ||
        filter.values === null ||
        filter.values.length === 0
      ) {
        filteredValues.value.splice(filteredValues.value.indexOf(filter), 1);
      } else if (filter.scale == "twin_slider") {
        let filterToChange = availableFilters.value.find(
          (element) => element.id === filter.id
        );

        let min = filterToChange.slider_ends[0];
        let max = filterToChange.slider_ends[1];

        if (filter.values[0] == min && filter.values[1] == max) {
          filteredValues.value.splice(filteredValues.value.indexOf(filter), 1);
        }
      }
    });

    // Se após essa formatação do vetor, não restar nenhum filtro, chamamos todos os dados da categoria
    if (filteredValues.value.length === 0) {
      let subName = breadCrumb_path.value[breadCrumb_index.value]["name"];
      let subId = breadCrumb_path.value[breadCrumb_index.value]["id"];
      let url = process.env.VUE_APP_URL + "/getSubs";
      let nB = { subName, subId };
      fetch(url, {
        method: "POST",
        body: JSON.stringify(nB),
        headers: new Headers({
          "content-type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((content) => {
          categoriesToDisplay.value = [];

          const sub_category = {
            name: subName,
            id: subId,
            sub_categories: content.sub_cats,
            products: content.products,
          };

          categoriesToDisplay.value.push(sub_category);
        })
        .catch((err) => {
          console.error("Erro na /getSubcategories(front): " + err);
        });
      return;
    }

    fetch(url5, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((content) => {
        console.log("filterProducts (/getFilteredResults) RESPONSE", content);
        console.log("filterProducts (/getFilteredResults) REQ", data);

        const catName = breadCrumb_path.value[breadCrumb_index.value]["name"];
        const catId = breadCrumb_path.value[breadCrumb_index.value]["id"];

        const category = {
          name: catName,
          id: catId,
          products: content,
        };

        categoriesToDisplay.value = [];

        categoriesToDisplay.value.push(category);
      })
      .catch((err) => {
        console.error("Erro na filterProducts (/getFilteredResults): " + err);
      });
  }

  function getCategoryFilters(id_category) {
    let url3 = process.env.VUE_APP_URL + "/getFilters";

    const data = {
      cat_id: id_category,
    };

    fetch(url3, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((content) => {
        console.log(
          "getCategoryFilters (/getCategoryFilters) RESPONSE",
          content
        );
        // console.log("getCategoryFilters (/getCategoryFilters) REQ", data);
        availableFilters.value = content;

        for (let i = availableFilters.value.length - 1; i >= 0; i--) {
          const filter = availableFilters.value[i];
          if (
            filter.scale === "twin_slider" &&
            (filter.slider_ends[0] == null ||
              filter.slider_ends[0] == undefined) &&
            (filter.slider_ends[1] == null ||
              filter.slider_ends[1] == undefined)
          ) {
            console.log("FILTRO VAZIO");
            availableFilters.value.splice(i, 1);
          }

          if (filter.scale === "multiple_choice") {
            filter.value_selection.forEach((value) => {
              if (value.length == 0) {
                filter.value_selection.splice(
                  filter.value_selection.indexOf(value),
                  1
                );
              }
            });
          }
        }

        // availableFilters.value.forEach((filter) => {
        //   if (filter.scale === "twin_slider") {
        //     if (
        //       (filter.slider_ends[0] == null ||
        //         filter.slider_ends[0] == undefined) &&
        //       (filter.slider_ends[1] == null ||
        //         filter.slider_ends[1] == undefined)
        //     ) {
        //       console.log("FILTRO VAZIO");
        //       availableFilters.value.splice(
        //         availableFilters.value.indexOf(filter),
        //         1
        //       );
        //     }
        //   }

        //   if (filter.scale === "multiple_choice") {
        //     filter.value_selection.forEach((value) => {
        //       if (value.length == 0) {
        //         filter.value_selection.splice(
        //           filter.value_selection.indexOf(value),
        //           1
        //         );
        //       }
        //     });
        //   }
        // });

        filteredValues.value = [];
      })
      .catch((err) => {
        console.error("getCategoryFilters (/getCategoryFilters): " + err);
      });
  }

  function getSubcategories(subName, subId, fatherCategory) {
    // Zerando os valores de exibição logo no começo antes do assíncronismo
    searchFailed.value = false;
    categoriesToDisplay.value = [];
    queryStringSearch.value = "";
    if (
      fatherCategory &&
      breadCrumb_path.value.length == 1 &&
      fatherCategory.id != -1
    ) {
      breadCrumb_path.value.push({
        name: fatherCategory.name,
        id: fatherCategory.id,
      });
      breadCrumb_index.value++;
    }

    breadCrumb_path.value.push({ name: subName, id: subId });
    breadCrumb_index.value++;

    breadCrumb_path.value.forEach((element) => {
      if (element.name == "Resultado da pesquisa") {
        breadCrumb_path.value.splice(breadCrumb_path.value.indexOf(element), 1);
        breadCrumb_index.value--;
      }
    });

    let url = process.env.VUE_APP_URL + "/getSubs";
    let nB = { subName, subId };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(nB),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((content) => {
        console.log("getSubcategories (/getSubs) RESPONSE", content);
        console.log("getSubcategories (/getSubs) REQ", nB);

        categoriesToDisplay.value = [];

        const sub_category = {
          name: subName,
          id: subId,
          sub_categories: content.sub_cats,
          products: content.products,
        };

        categoriesToDisplay.value.push(sub_category);
        currentStepHome.value = "ProductsList";
        actionWatcher("Category");
        getCategoryFilters(subId);
      })
      .catch((err) => {
        console.error("Erro na /getSubcategories(front): " + err);
      });
  }

  function getProduct(product) {
    searchFailed.value = false;
    currentStepHome.value = "Product";
    searchedProduct.value = product;

    availableFilters.value = [];

    console.log("STORE PRODUCT: ", searchedProduct.value);

    breadCrumb_path.value.push({
      name: searchedProduct.value.name,
      id: searchedProduct.value.id,
      type: "product",
    });
    breadCrumb_index.value++;
    actionWatcher("Product");
  }

  return {
    currentStepHome,
    searchedProduct,
    searchFailed,
    searchResults,
    filteredValues,
    queryStringSearch,
    availableFilters,
    sidebarCategories,
    breadCrumb_path,
    breadCrumb_index,
    categoriesToDisplay,
    filterProducts,
    getSubcategories,
    getProduct,
    getInitialProductsSet,
    actionWatcher,
  };
});
