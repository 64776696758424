<script setup>
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";
import { onUpdated, ref, onMounted } from "vue";

const filterStore = useFilterStore();
const { filteredValues } = storeToRefs(filterStore);
const { filterProducts } = filterStore;

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
});

// background div
const sliderTrack = ref(null);

const MIN = props.options.slider_ends[0];
const MAX = props.options.slider_ends[1];
const min_gap = 1;

// V-models
const slider1 = ref(MIN);
const slider2 = ref(MAX);

function sliderOne() {
  if (parseInt(slider2.value) - parseInt(slider1.value) <= min_gap) {
    // slider1.value = parseInt(slider2.value) - min_gap;
  }
  fillColor();
}
function sliderTwo() {
  if (parseInt(slider2.value) - parseInt(slider1.value) <= min_gap) {
    // slider2.value = parseInt(slider1.value) + min_gap;
  }
  fillColor();
}

function fillColor() {
  let percent1 = ((slider1.value - MIN) / (MAX - MIN)) * 100;
  let percent2 = ((slider2.value - MIN) / (MAX - MIN)) * 100;
  sliderTrack.value.style.background = `linear-gradient(to right, #d5d5d5 ${percent1}%, #3264fe ${percent1}%, #3264fe ${percent2}%, #d5d5d5 ${percent2}%)`;
}

onUpdated(() => {
  const FILTER_ID = props.options.id;
  const FILTER_SLIDER_VALUES = [
    parseInt(slider1.value),
    parseInt(slider2.value),
  ];
  const FILTER_NAME = props.options.name;
  const FILTER_SCALE = props.options.scale;

  const formatted_object = {
    name: FILTER_NAME,
    values: FILTER_SLIDER_VALUES,
    scale: FILTER_SCALE,
    id: FILTER_ID,
  };

  let element = filteredValues.value.find(
    (element) => element.id === FILTER_ID
  );

  if (element) {
    element.values = FILTER_SLIDER_VALUES;
  } else {
    filteredValues.value.push(formatted_object);
  }
});

function searchFiltersOnEvent() {
  filterProducts();
}

function resetFilter() {
  slider1.value = MIN;
  slider2.value = MAX;
  fillColor();
  let element = filteredValues.value.find(
    (element) => element.id === props.options.id
  );

  if (element) {
    filteredValues.value = filteredValues.value.filter(
      (filter) => filter.id !== props.options.id
    );

    filterProducts();
  }
}

const modoMacaco = () => {
  console.log("macaco SLIDER");

  slider1.value = MIN;
  slider2.value = MAX;
  fillColor();
  let element = filteredValues.value.find(
    (element) => element.id === props.options.id
  );

  if (element) {
    filteredValues.value = filteredValues.value.filter(
      (filter) => filter.id !== props.options.id
    );

    filterProducts();
  }
};

defineExpose({
  modoMacaco,
  slider1,
  slider2,
});

const sliderInput1 = ref(null);
const sliderInput2 = ref(null);
onMounted(() => {
  fillColor();
  console.log(sliderInput1.value);
});
</script>

<template>
  <div class="wrapper">
    <div class="container" style="background-color: blue">
      <div class="slider-track" ref="sliderTrack"></div>
      <input
        type="range"
        :min="MIN"
        :max="MAX"
        @input="sliderOne"
        v-model="slider1"
        @mouseup="searchFiltersOnEvent"
        ref="sliderInput1"
        :step="(MAX - MIN) / 100"
      />
      <input
        type="range"
        :min="MIN"
        :max="MAX"
        @input="sliderTwo"
        v-model="slider2"
        @mouseup="searchFiltersOnEvent"
        ref="sliderInput2"
        :step="(MAX - MIN) / 100"
      />
    </div>
    <div class="subtitles">
      <p class="subtitles_slider1">{{ slider1 }}</p>
      <p class="subtitles_slider2">{{ slider2 }}</p>
    </div>
  </div>
  <button @click="resetFilter">Limpar filtro</button>
</template>

<style scoped>
.wrapper {
  min-width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitles {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subtitles_slider1 {
  margin-right: 5px;
  font-size: 12px;
}

.subtitles_slider2 {
  margin-left: 5px;
  font-size: 12px;
}

.container {
  max-width: 300px;
  width: 100%;
  position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}

.slider-track {
  width: 100%;
  height: 5px;
  background-color: #d5d5d5;
  position: absolute;
  /* margin: auto; */
  top: 0;
  bottom: o;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 5px;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 5px;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.3em;
  height: 1.3em;
  background-color: #3264fe;
  cursor: pointer;
  border-radius: 10px;
  margin-top: -5px;
  pointer-events: auto;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.3em;
  height: 1.3em;
  background-color: #3264fe;
  cursor: pointer;
  border-radius: 10px;
  margin-top: -5px;
  pointer-events: auto;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.3em;
  height: 1.3em;
  background-color: #3264fe;
  cursor: pointer;
  border-radius: 10px;
  margin-top: -5px;
  pointer-events: auto;
}

input[type="range"]:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 3px solid #3264fe;
}

button {
  margin-top: 12px;
  width: 100%;
  padding: 12px 20px;
  text-align: center;
}
</style>
