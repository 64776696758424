<script setup>
import { storeToRefs } from "pinia";
import { useFilterStore } from "../stores/filterStore";

import SearchBar from "../components/elements/SearchBar.vue";
import SideBar from "../components/elements/SideBar.vue";
import ProductsList from "../components/sections/ProductsList.vue";
import Product from "../components/sections/Product.vue";
import BreadCrumbs from "../components/elements/BreadCrumbs.vue";

const filterStore = useFilterStore();
const { currentStepHome } = storeToRefs(filterStore);

const components = {
  ProductsList,
  Product,
};
</script>
<template>
  <main>
    <SearchBar />
    <section>
      <div class="wrapper_sidebar">
        <SideBar />
      </div>
      <div class="wrapper">
        <BreadCrumbs />
        <component :is="components[currentStepHome]" />
      </div>
    </section>
  </main>
</template>

<style scoped>
section {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 100px;
  display: flex;
}

.wrapper_sidebar {
  position: relative;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 20px 0 20px 250px;
}

@media screen and (max-width: 500px) {
  .wrapper_sidebar {
    display: none;
  }
}
</style>
