<script setup>
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";
import { onUpdated, ref, onMounted, onUnmounted, watch } from "vue";

const filterStore = useFilterStore();
const { filteredValues } = storeToRefs(filterStore);
const { filterProducts } = filterStore;

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
});

const INPUT_VALUES = ref([]);
const FILTER_ID = props.options.id;
const FILTER_NAME = props.options.name;
const FILTER_SCALE = props.options.scale;

onUpdated(() => {
  const formated_object = {
    name: FILTER_NAME,
    values: INPUT_VALUES.value,
    id: FILTER_ID,
    scale: FILTER_SCALE,
  };

  let element = filteredValues.value.find(
    (element) => element.id === FILTER_ID
  );

  if (element) {
    element.values = INPUT_VALUES;
  } else {
    filteredValues.value.push(formated_object);
  }

  filterProducts();
});

function resetFilter() {
  INPUT_VALUES.value = [];

  let element = filteredValues.value.find(
    (element) => element.id === FILTER_ID
  );

  if (element) {
    filteredValues.value = filteredValues.value.filter(
      (filter) => filter.id !== FILTER_ID
    );

    console.log("achou e filtrou");
    console.log(filteredValues.value);
  }
}

const modoMacaco = () => {
  console.log("macaco CHECBOX");

  INPUT_VALUES.value = [];

  let element = filteredValues.value.find(
    (element) => element.id === FILTER_ID
  );

  if (element) {
    filteredValues.value = filteredValues.value.filter(
      (filter) => filter.id !== FILTER_ID
    );

    console.log("achou e filtrou");
    console.log(filteredValues.value);
  }
};

defineExpose({
  modoMacaco,
  INPUT_VALUES,
});
</script>

<template>
  <div class="container">
    <div
      v-for="(filter, index) in props.options.value_selection"
      :key="index"
      class="wrapper"
    >
      <input type="checkbox" :value="filter" v-model="INPUT_VALUES" />
      <label for="">{{ filter }}</label>
    </div>
    <button @click="resetFilter">Limpar filtro</button>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

div input {
  width: 16px;
  height: 16px;
}

button {
  margin-top: 12px;
  width: 100%;
  padding: 12px 20px;
  text-align: center;
}
</style>
