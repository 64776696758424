<script setup>
import { ref, onMounted } from "vue";
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";
import Accordion from "../Accordion.vue";
import TwinSlider from "../filters/TwinSlider.vue";
import MultipleChoice from "../filters/MultipleChoice.vue";

const filterStore = useFilterStore();
const {
  availableFilters,
  sidebarCategories,
  breadCrumb_path,
  breadCrumb_index,
  filteredValues,
} = storeToRefs(filterStore);

const { getSubcategories, filterProducts } = filterStore;

function goToSideCat(cat) {
  getSubcategories(cat.name, cat.id);

  breadCrumb_path.value = [];
  breadCrumb_path.value.push({ name: "Home", id: "0" });
  breadCrumb_path.value.push({ name: cat.name, id: cat.id });
  breadCrumb_index.value = 1;
}

onMounted(() => {});

const checkboxes = ref(null);
const sliders = ref(null);

function resetAll() {
  checkboxes.value.forEach((element) => {
    element.modoMacaco();
  });
  sliders.value.forEach((element) => {
    element.modoMacaco();
  });
}
</script>

<template>
  <aside v-if="availableFilters.length == 0">
    <p style="font-weight: 600">Categorias</p>
    <ul>
      <li
        v-for="cat in sidebarCategories"
        :key="cat.id"
        @click="goToSideCat(cat)"
      >
        {{ cat.name }}
      </li>
    </ul>
  </aside>
  <aside v-else>
    <ul class="ul_filters">
      <Accordion
        v-for="filter in availableFilters"
        :key="filter.id"
        :title="filter.name"
        v-if="!re_render_inputs"
        class="filter_accordion"
      >
        <TwinSlider
          v-if="filter.scale === 'twin_slider'"
          :options="filter"
          ref="sliders"
        />
        <MultipleChoice
          v-else-if="filter.scale === 'multiple_choice'"
          :options="filter"
          ref="checkboxes"
        />
      </Accordion>
      <button @click="resetAll">LIMPAR TODOS FILTROS</button>
    </ul>
  </aside>
</template>

<style scoped>
aside {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  border-right: 1px solid #e5e5e5;
  width: 220px;
  max-width: 220px;
  height: 100%;
  /* max-height: 100vh; */
  padding-bottom: 100px;
  position: fixed;
}

ul {
  width: 210px;
  max-height: 100vh;
  max-width: 100%;
  overflow: scroll;
  list-style: none;
}

ul::-webkit-scrollbar {
  width: 5px; /* largura da barra de rolagem */
}

ul::-webkit-scrollbar-track {
  background: transparent; /* cor de fundo da pista da barra de rolagem */
}

ul::-webkit-scrollbar-thumb {
  background: #888; /* cor do indicador da barra de rolagem */
  border-radius: 10px; /* borda do indicador da barra de rolagem */
}

.filter_accordion {
  margin: 15px 0;
}

li {
  margin-bottom: 12px;
  padding: 12px 0px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-overflow: ellipsis;
}

li:hover {
  background-color: #ffa47b;
}
</style>
