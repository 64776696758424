import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import "./assets/style.css";
// import { createI18n } from "vue-i18n";

// import "./assets/styles/reset.css";
// import "./assets/styles/normalize.css";
// import messages from "./assets/translation";

// const i18n = createI18n({
//   locale: "en", // set locale
//   fallbackLocale: "en", // set fallback locale
//   messages, // set locale messages
//   // If you need to specify other options, you can set other options
//   // ...
// });

const app = createApp(App);

// app.use(i18n);

app.use(createPinia());

app.mount("#app");
