<script setup>
import { onMounted } from "vue";
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";
import ProductCategoryCard from "../ProductCategoryCard.vue";

const filterStore = useFilterStore();
const {
  sidebarCategories,
  categoriesToDisplay,
  breadCrumb_index,
  searchFailed,
} = storeToRefs(filterStore);

const { getSubcategories, getProduct, getInitialProductsSet } = filterStore;

// Pegando as categorias da barra lateral que irão para sidebar
function getSideCat() {
  let url = process.env.VUE_APP_URL + "/getSideCat";
  fetch(url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((content) => {
      sidebarCategories.value = content;
    })
    .catch((err) => {
      console.error("Erro na /getSideCat: ", err);
    });
}

onMounted(() => {
  if (breadCrumb_index.value == 0) {
    getInitialProductsSet();
  }

  getSideCat();
});
</script>

<template>
  <div class="container" v-if="searchFailed == false">
    <div class="row" v-for="category in categoriesToDisplay" :key="category.id">
      <h2>{{ category.name }}</h2>
      <div v-if="category.sub_categories" class="card_container">
        <ProductCategoryCard
          v-for="subcat in category.sub_categories"
          :key="subcat.id"
          :name="subcat.name"
          :id="subcat.id"
          :isProduct="false"
          :category_img="subcat.image"
          @click="
            getSubcategories(subcat.name, subcat.id, {
              name: category.name,
              id: category.id,
            })
          "
        />
      </div>
      <div v-if="category.products" class="card_container">
        <ProductCategoryCard
          v-for="product in category.products"
          :key="product.id"
          :name="product.name"
          :id="product.id"
          :in_stock="product.in_stock"
          :price="product.price"
          :manufacturer="product.manufacturer"
          :isProduct="true"
          @click="getProduct(product)"
        />
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div class="row">
      <h2 style="text-align: center">
        Oops, não encontramos o que você estava procurando no nosso catálogo
        ativo. <br />Fale com a gente que conseguimos para você.
      </h2>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.row {
  width: 100%;
  margin-bottom: 30px;
}

.row .card_container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin: 10px 20px 10px 0;
  max-width: 120px;
  width: 100%;
}

.card .card_image_wrapper {
  width: 120px;
  height: 100px;
  background-color: #c6c6c6;
  margin-bottom: 10px;
}

.card p {
  font-size: 14px;
}
</style>
