import { defineStore } from "pinia";
import { ref } from "vue";

export const usePageSelector = defineStore("pageSelector", () => {
  //const allowedPages = ref([]) // Páginas que o usuário pode acessar
  //const pagesElements = ref([]) // Elementos das páginas que o usuário pode acessar
  const currentPage = ref("Home"); // Página que está sendo exibida na Main.vue
  const currentForm = ref("LoginForm"); //Formulário atual, Login, Recovery etc...
  const cadastroOk = ref("False"); //Aviso de cadastro completo no LoginForm

  // Alterar a página atual (se o usuário realmente possuir acesso):
  // redirectTo(page) {
  //   const isAllowed = this.allowedPages.some((el) => el.name === page);
  //   if (!isAllowed) {
  //     this.currentPage = "NotFound";
  //     return;
  //   }

  //   this.currentPage = page;
  // },

  // Request das páginas que o usuário pode ter acesso, salvando-as no state allowedPages.
  // async getAllowedPages() {
  //   let url = process.env.VUE_APP_URL + '/contextPages'
  //   let result = await fetch(url, {
  //     method: "GET",
  //   });
  //   const data = await result.json();
  //   console.log(JSON.stringify(data));
  //   if (data.invalidSession) {
  //     console.log("IF Get Allowed Pages:" + data.invalidSession);
  //     viewSelector().currentView = "Login";
  //   } else {
  //     this.allowedPages = data.pageArray;
  //     console.log("AllowedPages: ", data.pageArray);
  //     return data.pageArray;
  //   }
  // },

  // Obter os elementos de uma determinada página:
  //PROBLEMA, não altera o currentView para a página correta Importa View Selector
  // async requestPageElements(page) {
  //   // Verificar se a página está na lista de páginas permitidas:
  //   const target = this.allowedPages.find((el) => el.name === page);
  //   if (!target) {
  //     this.currentPage = "NotFound";
  //     console.log('Page target not found in allowedPages!')
  //     return
  //   }

  //   // Verificar se a página já possui elementos armazenados na store:
  //   const isStored = this.pagesElements.find((el) => el.name === target.name);
  //   if (isStored) {
  //     // if user sessions isn`t expired
  //     return isStored.elements;
  //     //else...
  //   }

  //   let url = process.env.VUE_APP_URL + '/getElements';
  //   let p_id = target.id;
  //   let nB = { p_id };
  //   const result = await fetch(url, {
  //     method: "POST",
  //     body: JSON.stringify(nB),
  //     headers: new Headers({
  //       "content-type": "application/json",
  //     }),
  //   });
  //   const data = await result.json();
  //   console.log(JSON.stringify(data));
  //   if (data.invalidSession) {
  //     console.log("IF Get Elements:" + data.invalidSession);
  //     viewSelector().currentView = "Login";
  //     return;
  //   } else {

  //     this.pagesElements.push({ name: page, elements: data.elemArray });
  //     console.log(page + " elements: ", data.elemArray);
  //     console.log("pageElements: ", this.pagesElements)
  //     return data.elemArray;
  //   }
  // },

  // Caso o nível de acesso do usuário seja alterado durante a sessão, deve-se resetar o this.pagesElements e chamar getAllowedPages() novamente.

  return { currentPage, currentForm, cadastroOk };
});
