<script setup>
import { onUpdated, ref, onMounted } from "vue";
import { useFilterStore } from "../../stores/filterStore";
import { storeToRefs } from "pinia";

import IconSearch from "../../assets/icons/IconSearch.vue";
import IconHamburguer from "../../assets/icons/IconHamburguer.vue";
import IconCart from "../../assets/icons/IconCart.vue";

const filterStore = useFilterStore();
const {
  categoriesToDisplay,
  queryStringSearch,
  currentStepHome,
  breadCrumb_path,
  breadCrumb_index,
  availableFilters,
  searchFailed,
} = storeToRefs(filterStore);

const { getInitialProductsSet } = filterStore;

function search() {
  const url = process.env.VUE_APP_URL + "/search";
  const nB = { query_string: queryStringSearch.value };

  fetch(url, {
    method: "POST",
    body: JSON.stringify(nB),
    headers: new Headers({
      "content-type": "application/json",
    }),
  })
    .then((res) => res.json())
    .then((content) => {
      if (content.products.length <= 0 && content.sub_cats.length <= 0) {
        console.log("NÃO ACHOU NADA ", content);
        categoriesToDisplay.value = [];
        searchFailed.value = true;
      } else {
        console.log("ACHOU ALGO NA SEARCH ", content);
        searchFailed.value = false;

        const fakeCategory = {
          name: "Resultado da busca",
          id: -1,
          sub_categories: content.sub_cats,
          products: content.products,
        };

        categoriesToDisplay.value = [];
        categoriesToDisplay.value.push(fakeCategory);

        currentStepHome.value = "ProductsList";
        breadCrumb_path.value = [];
        breadCrumb_index.value = 1;

        breadCrumb_path.value.push({ name: "Home", id: 0 });
        breadCrumb_path.value.push({ name: "Resultado da pesquisa", id: -1 });

        availableFilters.value = [];

        console.log("CATEGORIES TO DISPLAY ", categoriesToDisplay.value);
      }
    });
}

function goBackHome() {
  currentStepHome.value = "ProductsList";
  breadCrumb_path.value = [];
  breadCrumb_index.value = 0;

  availableFilters.value = [];

  categoriesToDisplay.value = [];

  breadCrumb_path.value.push({ name: "Home", id: 0 });

  getInitialProductsSet();

  queryStringSearch.value = "";
}

const inputFocused = ref(false);
</script>

<template>
  <nav>
    <div class="logo" @click="goBackHome">
      <img
        src="../../assets/img/brand/logo-regular-laranja.svg"
        alt=""
        class="logo_desktop"
      />
      <img
        src="../../assets/img/brand/logo-icon-laranja.svg"
        alt=""
        class="logo_mobile"
      />
    </div>
    <div class="input-wrapper" :class="{ 'input-focused': inputFocused }">
      <input
        type="text"
        placeholder="Pesquisa ONSTOQ.com"
        v-model="queryStringSearch"
        @focus="inputFocused = true"
        @blur="inputFocused = false"
        @keyup.enter="search"
      />
      <button @click="search">
        <IconSearch />
      </button>
    </div>
    <div class="buttons">
      <div>
        <IconCart />
      </div>
      <div>
        <IconHamburguer />
      </div>
    </div>
  </nav>
</template>

<style scoped>
nav {
  width: 100vw;
  max-width: 100%;
  height: 100px;
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px lightgray solid;
  background-color: #ffffff;
  gap: 40px;
}

.logo {
  height: 90px;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

.input-wrapper {
  width: 100%;
  max-width: 1000px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  display: flex;
}

.input-wrapper input {
  width: 100%;
  padding: 12px 10px;
  border-radius: 2px;
  border: none;
  outline: none;
}

.input-wrapper button {
  width: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}

.input-wrapper.input-focused {
  border-color: #ff4f00;
}

.buttons {
  display: flex;
}

.buttons div {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.8s ease;
}

.loog_desktop {
  display: block;
}

.logo_mobile {
  display: none;
}

@media screen and (max-width: 650px) {
  nav {
    gap: 15px;
  }
  /* .input-wrapper {
    display: none;
  } */

  .logo {
    height: 50px;
    width: 50px;
    min-width: 40px;
  }

  .logo_desktop {
    display: none;
  }

  .logo_mobile {
    display: block;
  }
}
</style>
