<script setup>
import { usePageSelector } from "./stores/pageSelector";
import { useFilterStore } from "./stores/filterStore";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

import Home from "./pages/Home.vue";

const pageSelectorStore = usePageSelector();
const { currentPage } = storeToRefs(pageSelectorStore);

const filterStore = useFilterStore();
const { actionWatcher } = filterStore;

const components = {
  Home,
};

onMounted(() => {
  let url = process.env.VUE_APP_URL + "/verification";
  fetch(url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((content) => {
      console.log("App.vue content:", content);
      actionWatcher("Home");
    })
    .catch((err) => {
      console.error("Erro na /verification: ", err);
    });
  console.log("Antes de rodar o watcher");
});
</script>

<template>
  <component :is="components[currentPage]"></component>
</template>

<style></style>
