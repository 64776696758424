<script setup>
const props = defineProps({
  isProduct: Boolean,
  category_img: String,
  name: String,
  price: String,
  manufacturer: String,
  in_stock: Boolean,
  ean: String,
  id: String,
});
</script>

<template>
  <div class="card" tabindex="0">
    <div
      class="card_image_wrapper"
      :style="{
        'background-image': `url(https://storage.googleapis.com/img_products/${props.id}_1_standard_1.jpg)`,
      }"
      v-if="props.isProduct"
    ></div>
    <div
      class="card_image_wrapper"
      :style="{
        'background-image': `url(https://storage.googleapis.com/img_products/${props.category_img})`,
      }"
      v-else-if="props.category_img && !isProduct"
    ></div>
    <div class="card_image_wrapper" v-else>
      <img src="../assets/img/404_onstoq.svg" alt="404 imagem não encontrada" />
    </div>
    <p v-if="props.category_img && !isProduct" style="text-align: center">
      {{ props.name }}
    </p>
    <p v-else>{{ props.name }}</p>
  </div>
</template>

<style scoped>
.card {
  margin: 20px 60px 20px 0;
  max-width: 120px;
  width: 100%;
  cursor: pointer;
}

.card:focus p {
  font-weight: bold;
}

.card .card_image_wrapper {
  width: 120px;
  height: 100px;
  /* background-color: #c6c6c6; */
  margin-bottom: 10px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card .card_image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card p {
  font-size: 14px;
  max-width: 120px;
  word-wrap: break-word;
  /* transition: all 0.3s ease; */
}

.card:hover p {
  font-weight: bold;
}
</style>
